<template>
  <v-container fluid>
    <page-header title="優惠券">
      <template #rightSlot>
        <v-btn depressed color="primary accent-4" class="white--text" :to="{ name: 'CreateCoupon' }">新增</v-btn>
      </template>
    </page-header>

    <datatable
      :tableHeaders="tableHeaders"
      :tableData="tableData"
      :page.sync="tablePage"
      :pageLimit.sync="tablePageLimit"
      :itemTotal="tableItemTotal"
      :isLoading="tableLoading"
      actionViewRouteLink="CouponDetail"
      @options-update="getList()"
    >
      <template #item.discount_type="{item}">
        <span v-if="item.discount_type === 'percentage'">百分比</span>
        <span v-else-if="item.discount_type === 'price'">價格優惠</span>
      </template>
    </datatable>
  </v-container>
</template>

<script>
import Datatable from '@/components/Datatable.vue'

export default {
  name: 'CouponList',
  components: {
    Datatable,
  },
  data: () => ({
    tableLoading: false,
    tablePage: 1,
    tablePageLimit: 10,
    tableData: [],
    tableItemTotal: 0,

    // --- static data
    tableHeaders: [
      { text: '優惠券名稱', value: 'name', width: 130 },
      { text: '優惠類型', value: 'discount_type' },
      { text: '優惠詳情', value: 'discount_detail', width: 150 },
      { text: '使用期限', value: 'expiry_date' },
      { text: '優惠碼', value: 'code' },
      { text: '狀態', value: 'status' },
      { text: '', value: 'actionView', align: 'end' },
    ],
  }),
  methods: {
    getQueries() {
      const query = this.$route.query
      if (this.$validate.regexNumber(query['p']) && parseInt(query['p']) > 0) {
        this.tablePage = parseInt(query['p'])
      }

      if (this.$validate.regexNumber(query['l']) && parseInt(query['l']) > 0) {
        this.tablePageLimit = parseInt(query['l'])
      }
      this.getList(true)
    },

    async getList(pageInit = false) {
      if (!pageInit) {
        const newQuery = {
          p: this.$validate.regexNumber(this.tablePage) && parseInt(this.tablePage) > 1 ? this.tablePage : undefined,
          l:
            this.$validate.regexNumber(this.tablePageLimit) && parseInt(this.tablePageLimit) !== 10
              ? this.tablePageLimit
              : undefined,
        }

        const validNewQuery = this.$common.validNewQuery(this.$route.query, newQuery)
        if (validNewQuery) {
          this.$router.replace({ query: newQuery })
        }
      }

      this.tableLoading = true

      try {
        let payload = {
          limit: this.tablePageLimit,
          page: this.tablePage - 1,
        }

        const user = this.getCurrentUserData()
        const { data, total } = await this.$Fetcher.GetCoupons(payload, user.id, user.token)
        this.tableItemTotal = total
        this.tableData = data.map(item => {
          let due_date = '-'
          if (item.start_date && item.expiry_date) {
            due_date = `${item.start_date} - ${item.expiry_date}`
          } else if (item.start_date) {
            due_date = `${item.start_date}起至另行通知`
          } else if (item.expiry_date) {
            due_date = item.expiry_date
          }

          let detail = ''
          if (item.discount_type === 'price') {
            detail = `- $${item.discount_value}`
          } else if (item.discount_type === 'percentage') {
            if (item.discount_value <= 0) {
              detail = '10折'
            } else {
              if (item.discount_value % 10 === 0) {
                detail = `${(item.discount_value * 0.1).toFixed(0)}折`
              } else {
                detail = `${(item.discount_value * 0.1).toFixed(1)}折`
              }
            }

            if (item.discount_value) {
              if (parseInt(item.discount_value) >= 100) {
                detail = '10折'
              } else {
                const d = 100 - parseFloat(item.discount_value)
                if (d % 10 === 0) {
                  detail = `${(d * 0.1).toFixed(0)}折`
                } else {
                  detail = `${(d * 0.1).toFixed(1)}折`
                }
              }
            }
          }

          return {
            id: item.id,
            name: item.name,
            discount_type: item.discount_type,
            discount_detail: detail,
            expiry_date: due_date,
            code: item.code,
            status: item.status,
          }
        })
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.$store.dispatch('toggleSnack', {
            type: 'error',
            message: err,
          })
        }
        this.tableData = []
      } finally {
        this.tableLoading = false
      }
    },
  },

  mounted() {
    this.getQueries()
  },
}
</script>
